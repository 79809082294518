import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SunriseSun } from '../icons/sun-svg-icon';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';

import '../css/Home.css';
import { sleep } from '../utils/async';

const DailyQuote =
  'Keep your face always toward the sunshine and shadows will fall behind you.';
const QuoteAuthor = 'Walt Whitman';

function HomePage({ onLoad, isLoaded }) {
  const navigate = useNavigate();
  const [quote, setQuote] = useState(isLoaded ? DailyQuote : undefined);
  const [author, setAuthor] = useState(isLoaded ? QuoteAuthor : undefined);

  useBackground(SunTypes.Twilight);

  const onLoadPageLoad = () => {
    onLoad();
    setQuote(DailyQuote);
    setAuthor('Walt Whitman');
  };

  useEffect(() => {
    if (!isLoaded) {
      sleep(150).then(onLoadPageLoad);
    }
  }, []);

  const { openModal } = useModal();
  const openSignUpModal = useCallback(() => openModal(ModalTypes.SignUp), []);

  return (
    <div className="App">
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} isLoaded={isLoaded} />
      <div className="content-container">
        {quote && (
          <>
            <div className="buttons-container">
              <button
                className="gradient-button button-large"
                onClick={() => navigate('/clock')}
              >
                Circadian Clock
              </button>
              <button
                className="gradient-button button-large"
                onClick={() => navigate('/uv')}
              >
                UV Index
              </button>
              <button
                className="gradient-button button-large"
                onClick={() => navigate('/learn-more')}
              >
                Learn More
              </button>
            </div>
            <div className="quote-container">
              “{quote}” {author && <>&nbsp;&nbsp;―{author}</>}
            </div>
          </>
        )}
        <div className="sunrise-container">
          <SunriseSun radius={75} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
