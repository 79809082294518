import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  loadSkinTypeDataFromCache,
  saveSkinTypeDataToCache,
} from '../utils/local-storage';
import { useSolarClock } from '../providers/solar-clock-provider';
import { fetchUVIndex } from '../utils/uv-utils';
import { useInterval } from '../hooks/use-interval';
import { DateTime } from 'luxon';

const UvContext = createContext();

const UpdateInterval = 60 * 1000 * 15;

export const UvProvider = ({ children }) => {
  const [skinType, setSkinType] = useState(undefined);
  const [uvIndex, setUvIndex] = useState(undefined);
  const [maxUv, setMaxUv] = useState(undefined);
  const [maxUvTime, setMaxUvTime] = useState(undefined);

  const count = useInterval(UpdateInterval);

  const { coordinates, timezone } = useSolarClock();

  useEffect(() => {
    void loadSkinTypeDataFromCache().then((st) => {
      if (st) {
        setSkinType(st);
      }
    });
  }, []);

  useEffect(() => {
    fetchUVIndex(coordinates.latitude, coordinates.longitude).then((data) => {
      setUvIndex(Math.round(data.uvIndex));
      setMaxUv(Math.round(data.maxUv));
      setMaxUvTime(DateTime.fromISO(data.maxUvTime).setZone('America/New_York'));
    });
  }, [coordinates.latitude, coordinates.longitude, count]);

  const saveSkinType = (newSkinType) => {
    saveSkinTypeDataToCache(newSkinType);
    setSkinType(newSkinType);
  };

  return (
    <UvContext.Provider value={{ skinType, saveSkinType, uvIndex, maxUv, maxUvTime }}>
      {children}
    </UvContext.Provider>
  );
};

// Custom hook to use the UvContext in components
export const useUvContext = () => {
  return useContext(UvContext);
};
