import React, { useState } from 'react';
import '../css/HamburgerMenu.css'; // Import the CSS file
import { useNavigate, useLocation } from 'react-router-dom';
import { getSunTypeGradient } from '../domain/sun-type';
import { useInfoPanel } from './info-panel';

const HamburgerMenu = ({ menuItems, darkTheme, sunType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { hideInfoPanel } = useInfoPanel();
  const gradient = getSunTypeGradient(sunType);
  const barClass = darkTheme ? 'bar bar-dark' : 'bar';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-container">
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className={barClass}></span>
        <span className={barClass}></span>
        <span className={barClass}></span>
      </div>

      <nav className={`menu ${isOpen ? 'open' : ''}`} style={{ background: gradient }}>
        <ul>
          {menuItems.map((item, index) => {
            const isActiveItem = location.pathname.includes(item.link);

            console.log('location.pathname', location.pathname);
            console.log('item.link', item.link);
            const onClick = () => {
              if (isActiveItem) {
                return;
              }
              hideInfoPanel();
              navigate(item.link);
            };

            return (
              <li key={item.key} className={`menu-item ${index === 0 ? 'top-item' : ''}`}>
                <div
                  onClick={onClick}
                  className={`item-name ${isActiveItem ? 'active-item' : ''}`}
                >
                  {item.name}
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
