import React, { useState } from 'react';
import axios from 'axios';
import { MailerLiteAPIKey } from '../config';
import '../css/SignUp.css';
import ThreeDotsBounce from '../icons/three-dots-bounce';
import { useAuth } from '../providers/auth-provider';
import { useModal } from '../providers/modal-provider';

const AddSubscriber = ({ isCodeRequired }) => {
  const [inviteCode, setInviteCode] = useState('');
  const [subscriberName, setSubscriberName] = useState('');
  const [subscriberEmail, setSubscriberEmail] = useState('');
  const [referrer, setReferrer] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { authenticate, isValidCode } = useAuth();
  const { closeModal } = useModal();

  function getSuccessMessage() {
    return isCodeRequired ? 'You now have access!' : "You've been added to the list!";
  }

  function getFailureMessage() {
    return isCodeRequired
      ? 'There was an error getting access for the preview release, please try again.'
      : 'There has been an error adding you to the list, please try again.';
  }

  const addSubscriber = async (name, email, referralSource) => {
    console.log('Adding subscriber', name, email, referralSource);
    const url = 'https://connect.mailerlite.com/api/subscribers';
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${MailerLiteAPIKey}`,
    };
    const data = {
      email,
      fields: {
        name,
        referral_source: referralSource,
      },
    };

    try {
      const response = await axios.post(url, data, { headers });
      if (response.status === 200 || response.status === 201) {
        setMessage(getSuccessMessage());
        if (isCodeRequired) {
          authenticate();
          closeModal();
        }
      } else {
        setMessage(getFailureMessage());
      }
    } catch (error) {
      setMessage(`Error: ${error.response ? error.response.data : 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  const isCodeValid = () => {
    return inviteCode != null && isValidCode(inviteCode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (isCodeRequired) {
    //   if (!inviteCode) {
    //     setMessage('Please enter an invite code.');
    //   } else if (!isCodeValid()) {
    //     setMessage('Please enter a valid invite code.');
    //     return;
    //   }
    // }
    if (subscriberName && subscriberEmail && referrer) {
      setLoading(true);
      void addSubscriber(subscriberName, subscriberEmail, referrer);
    } else {
      setMessage('Please enter an email address.');
    }
  };

  return (
    <div className="addSubscriber">
      <h3>{isCodeRequired ? 'PREVIEW RELEASE' : 'SIGN UP FOR MY NEWSLETTER'}</h3>
      <div>
        {!isCodeRequired &&
          'Sign up with your email address to receive occasional updates, including insights\n' +
            '        on circadian and quantum biology, news about the site, and product\n' +
            '        recommendations.'}
        {isCodeRequired &&
          'Please enter your name, email address and how you hear about us to get access to the Sunlight is Life preview release.'}
      </div>
      <div className="signUpForm">
        <form onSubmit={handleSubmit}>
          {isCodeRequired && (
            <input
              type="text"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
              placeholder="Invite Code"
              required
              className="form-input form-input-medium"
              style={{ width: '95%' }}
            />
          )}
          <div className="emailInput">
            <input
              type="string"
              value={subscriberName}
              onChange={(e) => setSubscriberName(e.target.value)}
              placeholder="Your Name"
              required
              className="form-input form-input-medium"
            />
          </div>
          <div className="emailInput">
            <input
              type="email"
              value={subscriberEmail}
              onChange={(e) => setSubscriberEmail(e.target.value)}
              placeholder="Email Address"
              required
              className="form-input form-input-medium"
            />
          </div>
          <div className="emailInput">
            <input
              type="string"
              value={referrer}
              onChange={(e) => setReferrer(e.target.value)}
              placeholder="How did you hear about us?"
              required
              className="form-input form-input-medium"
            />
          </div>
          <div className="emailInput">
            <button
              type="submit"
              className="gradient-button button-medium"
              disabled={loading}
            >
              {loading ? <ThreeDotsBounce /> : 'Sign Up'}
            </button>
          </div>
        </form>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddSubscriber;
