// Import Luxon (you'll need to install it via npm if you're using Node.js: npm install luxon)
import axios from 'axios';

const { DateTime } = require('luxon');

const OpenUvAPIKey = 'openuv-1mxfsvrm1zez9ip-io';

// Enum for Fitzpatrick Skin Types
export const SkinType = {
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
};

// Function to get MED based on skin type
function getMEDForSkinType(skinType) {
  const MED_VALUES = {
    [SkinType.I]: 200, // Type I
    [SkinType.II]: 250, // Type II
    [SkinType.III]: 300, // Type III
    [SkinType.IV]: 450, // Type IV
    [SkinType.V]: 600, // Type V
    [SkinType.VI]: 800, // Type VI
  };

  return MED_VALUES[skinType] || null;
}

// Function to calculate altitude adjustment factor
function getAltitudeFactor(altitude) {
  // 10% increase in UV for every 1,000 meters
  return 1 + (altitude / 1000) * 0.1;
}

// Function to calculate latitude adjustment factor
function getLatitudeFactor(latitude) {
  if (Math.abs(latitude) <= 30) {
    return 1; // Low latitude
  } else if (Math.abs(latitude) <= 60) {
    return 0.8; // Mid latitude
  } else {
    return 0.6; // High latitude
  }
}

// Function to determine season factor based on date (Luxon DateTime object) and latitude
function getSeasonFactor(latitude, date) {
  const month = date.month; // Luxon provides month directly (1-12)

  if (latitude >= 0) {
    // Northern hemisphere
    if (month >= 6 && month <= 8) {
      return 1; // Summer
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  } else {
    // Southern hemisphere
    if (month === 12 || month === 1 || month === 2) {
      return 1; // Summer in Southern hemisphere
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  }
}

// Function to calculate total adjustment factor
function calculateTotalAdjustmentFactor(altitude, latitude, date) {
  const altitudeFactor = getAltitudeFactor(altitude);
  const latitudeFactor = getLatitudeFactor(latitude);
  const seasonFactor = getSeasonFactor(latitude, date);
  return altitudeFactor * latitudeFactor * seasonFactor;
}

// Main function to calculate safe sun exposure time
export function calculateSafeSunExposureTime(
  uvIndex,
  altitude,
  latitude,
  date,
  skinType,
) {
  const MED = getMEDForSkinType(skinType);
  if (!MED) {
    return 'Invalid skin type.';
  }

  const totalAdjustmentFactor = calculateTotalAdjustmentFactor(altitude, latitude, date);
  const safeExposureTime = MED / (uvIndex * totalAdjustmentFactor);
  return Math.round(safeExposureTime);
}

export async function fetchUVIndex(latitude, longitude) {
  const openUVURL = `https://api.openuv.io/api/v1/uv`;

  try {
    const response = await axios.get(openUVURL, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': OpenUvAPIKey,
      },
      params: {
        lat: latitude,
        lng: longitude,
      },
    });

    const data = response.data;

    if (data && data.result) {
      const { uv: uvIndex, uv_max: maxUv, uv_max_time: maxUvTime } = data.result;
      return { uvIndex, maxUv, maxUvTime };
    } else {
      console.warn('No UV data found for the given location.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching UV data:', error);
  }
}
