import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import SolarClock from './pages/solar-clock';
import UltraVioletPage from './pages/ultra-violet';
import './css/App.css';
import CircadianScience from './pages/circadian-science';
import SolarEvents from './pages/solar-events';
import useBrowserInfo from './hooks/use-browser-info';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration) => {
        console.log('ServiceWorker registered: ', registration);
      })
      .catch((error) => {
        console.log('ServiceWorker registration failed: ', error);
      });
  });
}

export default function App() {
  const [isClockLoaded, setIsClockLoaded] = useState(false);
  const [isHomeLoaded, setIsHomeLoaded] = useState(false);
  const { isMobileLayout } = useBrowserInfo();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <HomePage onLoad={() => setIsHomeLoaded(true)} isLoaded={isHomeLoaded} />
          }
        />
        <Route
          path="/clock/:debug?"
          element={
            isMobileLayout ? (
              <SolarEvents
                onLoad={() => setIsClockLoaded(true)}
                isLoaded={isClockLoaded}
              />
            ) : (
              <SolarClock
                onLoad={() => setIsClockLoaded(true)}
                isLoaded={isClockLoaded}
              />
            )
          }
        />
        <Route
          path="/uv/:debug?"
          element={
            <UltraVioletPage
              onLoad={() => setIsClockLoaded(true)}
              isLoaded={isClockLoaded}
            />
          }
        />
        <Route
          path="/learn-more/:pageIndex?"
          element={<CircadianScience isLoaded={true} />}
        />
      </Routes>
    </Router>
  );
}
