import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../css/App.css';
import SunPath, { getLayoutType, SunTypes } from '../components/sun_path';
import { useSolarClock } from '../providers/solar-clock-provider';
import { formatDayTimeAndZone, getDateTime } from '../utils/time';
import { getSunType } from '../domain/sun-type';
import {
  displayBackgroundGradient,
  useBackgroundAnimation,
} from '../hooks/use-background-animation';
import { createSunPathAnimation, waitForRef } from '../utils/sun-animation';
import AppHeader from '../components/app_header';
import Navigation from '../components/navigation';
import useBrowserInfo from '../hooks/use-browser-info';
import { useInfoPanel } from '../components/info-panel';
import { Features } from '../utils/features';
import { DateTime } from 'luxon';

const now = DateTime.now();

const sunAnimationStartDate = DateTime.fromObject({
  year: now.year,
  month: now.month,
  day: now.day,
  hour: 1,
  minute: 12,
});

const sunAnimationStopDate = DateTime.fromObject({
  year: now.year,
  month: now.month,
  day: now.day,
  hour: 13,
  minute: 12,
});

function SolarClock({ onLoad, isLoaded }) {
  const {
    timezone,
    isLocationLoading,
    activeEvent,
    activateEvent,
    clearEvent,
    today,
    sunTimes,
    displayDate,
    isNight,
    moonPhase,
  } = useSolarClock();
  const [sunElevationTime, setSunElevationTime] = useState(getDateTime(today));
  const [isAnimationDarkMode, setIsAnimationDarkMode] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isSunAnimationRunning, setIsSunAnimationRunning] = useState(false);
  const [animatedSunTime, setAnimatedSunTime] = useState(sunAnimationStartDate);
  const currentDateRef = useRef();

  const { isMobileLayout } = useBrowserInfo();
  const layoutType = getLayoutType(isMobileLayout);

  const { hideInfoPanel } = useInfoPanel();
  const sunType = useMemo(() => {
    return getSunType(sunTimes, sunElevationTime);
  }, [sunTimes, sunElevationTime]);

  const { startBackgroundAnimation, isBackgroundAnimationRunning } =
    useBackgroundAnimation();

  useEffect(() => {
    if (!isBackgroundAnimationRunning) {
      displayBackgroundGradient(sunType);
    }
  }, [sunType, isBackgroundAnimationRunning]);

  /* Animation effects */

  //isLocationLoading ? undefined : sunElevationTime

  useEffect(() => {
    if (!isLocationLoading) {
      currentDateRef.current = sunElevationTime;
    }
  }, [isLocationLoading, sunElevationTime]);

  const handleOnFrame = (currentDateTime, isDarkTheme) => {
    setAnimatedSunTime(currentDateTime); // Update sun elevation time for each frame
    setIsAnimationDarkMode(isDarkTheme);
  };

  const createIntroAnimation = () => {
    return createSunPathAnimation(
      sunAnimationStartDate,
      sunAnimationStopDate,
      sunrise,
      sunset,
      handleOnFrame,
      3,
      1250,
      750,
    );
  };

  const createSunSyncAnimation = (currentTime) => {
    // The animation starts before we have the users location and timezone, so it uses a default zone
    // We need to make sure both times are in the same time zone so the animation works correctly.
    const alignedCurrentTime = currentTime.setZone(sunAnimationStopDate.zoneName).set({
      hour: currentTime.hour,
      minute: currentTime.minute,
      second: currentTime.second,
      millisecond: currentTime.millisecond,
    });

    return createSunPathAnimation(
      sunAnimationStopDate,
      alignedCurrentTime,
      sunrise,
      sunset,
      handleOnFrame,
      1,
      0,
      500,
    );
  };

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (!Features.animateSolarClockIsActive()) {
      onLoad();
      return;
    }

    let sunSyncAnimationTimer, introAnimationTimer;
    const animate = async () => {
      const introAnimation = createIntroAnimation();
      introAnimationTimer = await introAnimation.start();

      await waitForRef(currentDateRef);

      const sunSyncAnimation = createSunSyncAnimation(currentDateRef.current);
      sunSyncAnimationTimer = await sunSyncAnimation.start();
      setIsSunAnimationRunning(false);
      if (onLoad) {
        onLoad();
      }
    };

    void startBackgroundAnimation();
    void animate();
    setIsSunAnimationRunning(true);

    return () => {
      if (introAnimationTimer) clearInterval(introAnimationTimer); // Cleanup timer on unmount
      if (sunSyncAnimationTimer) clearInterval(sunSyncAnimationTimer); // Cleanup timer on unmount
    };
  }, [isLoaded]);

  /* end animation effect */

  useEffect(() => {
    setSunElevationTime(displayDate);
  }, [displayDate]);

  const { sunrise, sunset } = sunTimes;

  const isDarkTheme = useMemo(() => {
    if (isSunAnimationRunning) {
      return isAnimationDarkMode;
    } else {
      return isNight(sunElevationTime);
    }
  }, [sunElevationTime, sunrise, sunset, isSunAnimationRunning, isAnimationDarkMode]);

  const onHoverEvent = (_eventLabel, eventDate) => {
    activateEvent(_eventLabel);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    if (isMobileLayout) {
      return;
    }
    setIsHovering(false);
    clearEvent();
    hideInfoPanel();
  };

  const isAppLoaded = !isLocationLoading && !isSunAnimationRunning && isLoaded;

  return (
    <div className="App">
      <AppHeader
        darkTheme={isDarkTheme}
        showTime={true}
        sunType={SunTypes.Red}
        isLoaded={isLoaded}
      />
      <Navigation
        darkTheme={isDarkTheme}
        isLocationLoading={!isAppLoaded}
        sunType={sunType}
      />
      <div className="page-content">
        <SunPath
          sunType={sunType}
          sunElevationTime={isSunAnimationRunning ? animatedSunTime : sunElevationTime}
          onMouseLeave={onMouseLeave}
          moonPhase={moonPhase}
          sunTimes={sunTimes}
          onHoverEvent={onHoverEvent}
          timezone={timezone}
          layout={layoutType}
          activeEvent={activeEvent}
          isAnimationRunning={isSunAnimationRunning}
        />
      </div>
    </div>
  );
}

export default SolarClock;
