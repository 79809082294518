import React, { useEffect, useMemo, useRef, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import '../css/SlidingPanel.css';
import { InfoPanelState, useInfoPanel } from './info-panel';
import { getCircadianEventGradient } from '../components/circadian_event';
import { getSunTypeByCircadianEvent, isDayCircadianEvent } from './circadian_event';
import SunIcon, { isSunIcon } from '../icons/sun_icon';
import MoonPhase from '../icons/moon-svg';
import { MoonPhases } from '../hooks/use-moon-phase';

const SlidingPanel = ({ name, content, state }) => {
  const { hideInfoPanel, infoPanel, showInfoPanel, showInfoPanelPreview } =
    useInfoPanel();
  const contentRef = useRef(null);

  const gradient = useMemo(() => {
    return getCircadianEventGradient(infoPanel.name);
  }, [infoPanel.name]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.background = gradient;
    }
  }, [infoPanel.state, gradient]);

  useEffect(() => {
    if (state === InfoPanelState.Preview) {
      toggleToPreview();
    } else if (state === InfoPanelState.Full) {
      toggleToFull();
    } else {
      closePanel();
    }
  }, [state]);

  const onAfterOpen = () => {
    const contentElement = document.querySelector('.slide-pane__content');
    if (contentElement) {
      contentElement.style.background = gradient;
      contentElement.style.color = isDayCircadianEvent(infoPanel.name)
        ? 'black'
        : 'white';
    }
  };

  const sunType = getSunTypeByCircadianEvent(name);
  const sunIcon = isSunIcon(sunType) && <SunIcon sunType={sunType} radius={30} />;
  const moonIcon = !isSunIcon(sunType) && (
    <MoonPhase phase={MoonPhases.WaxingCrescent} radius={15} />
  );

  const toggleToPreview = () => {
    showInfoPanelPreview(infoPanel.name);
  };

  const toggleToFull = () => {
    console.log('Toggle to full');
    showInfoPanel(infoPanel.name);
  };

  const closePanel = () => {
    hideInfoPanel();
  };

  return (
    <>
      {infoPanel.state === InfoPanelState.Preview && (
        <div
          className={`custom-pane ${infoPanel.state}`}
          onClick={toggleToFull}
          style={{ background: gradient }}
        >
          <div className="toggle-button">{'◀'}</div>
        </div>
      )}
      {infoPanel.state === InfoPanelState.Full && (
        <SlidingPane
          isOpen={infoPanel.state !== InfoPanelState.Closed}
          from="right"
          width="100%"
          onRequestClose={closePanel}
          hideHeader
          className={`custom-pane ${infoPanel.state}`}
          overlayClassName="custom-overlay"
          style={{ background: gradient }}
          onAfterOpen={onAfterOpen}
        >
          <button className="close-btn" onClick={closePanel}>
            ×
          </button>
          <div>
            <div className="content-wrapper">{content}</div>
            {sunIcon && <div className="sun-icon-wrapper">{sunIcon}</div>}
            {moonIcon && <div className="moon-icon-wrapper">{moonIcon}</div>}
          </div>
        </SlidingPane>
      )}
    </>
  );
};

export default SlidingPanel;
